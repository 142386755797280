<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />
    
      <div class="page">
        <div class="subpage-banner"></div>
        <div class="subpage-content">

        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Typography</h1>
              <div class="pagehead-link"></div>
            </div>
            <p>
              As text makes up the majority of the State’s user interfaces, the
              Ohio Design System offers a text structure designed for readability
              while providing consistency in styling and sizing of type.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1200">

        <section>
          <div class="content-band" >
            <h3 class="mb-20 pl-10">Font Family</h3>


          <!-- <div class="container flex max-w1000 white p-20">
            <div class="flex flex-row">
            <div>
            <img src="../../assets/images/typo-icon-orange.png" alt="Source Sans Pro Icon" />
            </div>

            <div class="plr-20">
              <h4>Source Sans Pro</h4>
              <p>
                Source Sans Pro is an open-source, sans serif typeface created
                for legibility. With a variety of weights that read easily at
                all sizes, Source Sans Pro provides clear headers as well as
                highly-readable body text.
              </p>
            </div>
          </div>

          </div> -->

          <div class="tile white max-w1000" aria-label="foundations">
          <div class="tile_img">
            <img src="../../assets/images/typo-icon-orange.png" alt="Source Sans Pro Icon" />
          </div>
          <div class="tile_content">
            <h3>Source Sans Pro</h3>
            <p style="-webkit-line-clamp: 6;">
              Source Sans Pro is an open-source, sans serif typeface created
                for legibility. With a variety of weights that read easily at
                all sizes, Source Sans Pro provides clear headers as well as
                highly-readable body text.
            </p>
          </div>
        </div>
          

          


          <div class="flex-grid gap-20 mt-30 plr-10">

            <div class="tile-sml flex-auto ">
              <h1 class="xl upper lite">A a</h1>
              <div class="lower">Light</div>
            </div>

            <div class="tile-sml flex-auto">
              <h1 class="xl upper">A a</h1>
              <div class="lower">Regular</div>
            </div>

            <div class="tile-sml flex-auto">
              <h1 class="xl upper semibold">A a</h1>
              <div class="lower">Semi bold</div>
            </div>

            <div class="tile-sml flex-auto">
              <h1 class="xl upper bold">A a</h1>
              <div class="lower">Bold</div>
            </div>
          </div>

          </div>
        </section>

        <section id="Headings">
          <div class="content-band" id="headings">
          
          <h3>Headings</h3>
          <p>
            A strong visual hierarchy communicates the organization of content
            on the page. Utilizing proper header hierarchy aids readability,
            makes content easily scannable, and is critical for accessibility. 
            Font sizes are equated according to the major-third scale of 1.250 rem with a base-size of 16px.
          </p>
          <p>Headings are written in sentence case and are left-aligned.</p>

          <div class="mb-30">
          <div class="mtb-20 inline-flex align-center">
            <div class="white font-red inline-block p-5 pl-10 pr-10">H1</div>
            <div class="plr-20">2.441rem / 39.06px</div>
            <div>line height: 2.441rem / 39px</div>
          </div>

            <div class="pl-60">
              <h1 class="lite">H1 Source Sans Pro Light</h1>
              <h1 class="reg">H1 Source Sans Pro Regular</h1>
              <h1 class="semibold">H1 Source Sans Pro Semi-Bold</h1>
              <h1 class="bold">H1 Source Sans Pro Bold</h1>
            </div>
          </div>

          <div class="mb-30">
          <div class="mtb-20 inline-flex align-center">
            <div class="white font-red inline-block p-5 pl-10 pr-10">H2</div>
            <div class="plr-20">1.953rem / 31.25px</div>
            <div>line height: 2.0rem / 32px</div>
          </div>
   
            <div class="pl-60">
              <h2 class="lite">H2 Source Sans Pro Light</h2>
              <h2 class="reg">H2 Source Sans Pro Regular</h2>
              <h2 class="semibold">H2 Source Sans Pro Semi-Bold</h2>
              <h2 class="bold">H2 Source Sans Pro Bold</h2>
            </div>
          </div>

          <div class="mb-30">
          <div class="mtb-20 inline-flex align-center">
            <div class="white font-red inline-block p-5 pl-10 pr-10">H3</div>
            <div class="plr-20">1.563rem / 25.00px</div>
            <div>line height: 1.8rem / 29px</div>
          </div>
           
            <div class="pl-60">
              <h3 class="lite">H3 Source Sans Pro Light</h3>
              <h3 class="reg">H3 Source Sans Pro Regular</h3>
              <h3 class="semibold">H3 Source Sans Pro Semi-Bold</h3>
              <h3 class="bold">H3 Source Sans Pro Bold</h3>
            </div>
          </div>

          <div>
            <div class="mtb-20 inline-flex align-center">
              <div class="white font-red inline-block p-5 pl-10 pr-10">H4</div>
              <div class="plr-20">1.25rem / 20.00px</div>
              <div>line height: 1.6rem / 26px</div>
            </div>

            <div class="pl-60">
              <h4 class="reg">H4 Source Sans Pro Regular</h4>
              <h4 class="semibold">H4 Source Sans Pro Semi-Bold</h4>
              <h4 class="bold">H4 Source Sans Pro Bold</h4>
            </div>
          </div>

          <div>
            <div class="mtb-20 inline-flex align-center">
              <div class="white font-red inline-block p-5 pl-10 pr-10">H5</div>
              <div class="plr-20">1.125rem / 18px</div>
              <div>line height: 1.125rem / 18px</div>
            </div>
          
          <div class="pl-60">
              <h5 class="reg">H5 Source Sans Pro Regular</h5>
              <h5 class="semibold">H5 Source Sans Pro Semi-Bold</h5>
              <h5 class="bold">H5 Source Sans Pro Bold</h5>
            </div>
          </div>

          </div>
        </section>

        <div class="divider"></div>

          <section id="Paragraphs">
          <div class="content-band" id="paragraphs">
          <h3>Paragraphs</h3>
          <p>
            Paragraph text is set to 16px and is left-aligned. 16px is the
            standard size for readability. All content blocks should default to
            this size.
          </p>
          <p>
            The maximum size for paragraph text is 20px. Lead-in sentences work
            well in larger fonts, but readers have difficulty scanning and
            reading paragraph text that is too large.
          </p>
          <p>
            In certain circumstances, a 14px font size (.875em) may be used when
            the text is supporting a directly related element, such as an image
            caption.
          </p>

         
            <div class="mtb-20 inline-flex align-center">
            <div class="white font-red inline-block p-5 pl-10 pr-10">p</div>
            <div class="plr-20">1rem / 16.00px</div>
            <div class="line-height inline-block">line height: 1.5rem</div>
          </div>

            <div class="pl-50">
              <div class="reg">P Source Sans Pro Regular</div>
              <div class="semibold">P Source Sans Pro Semi-Bold</div>
              <div class="bold">P Source Sans Pro Bold</div>
            </div>

  

          <div class="mt-40 p-30 white max-w800">
            <h4>Font-size Modifiers</h4>
            <div class="gray-100 p-20 mt-10">
              <div>
                <div class="inline-block orange-text pr-10 font-red">h1.xxl</div>
                <div class="inline-block">3.75rem / 60px</div>
              </div>
              <div class="mt-10">
                <div class="inline-block orange-text pr-10 font-red">h1.xl</div>
                <div class="inline-block">3.25rem / 52px</div>
              </div>
              <div class="mt-10">
                <div class="inline-block orange-text pr-10 font-red">h1.large</div>
                <div class="inline-block">3.052rem / 48.83px</div>
              </div>
              <div class="mt-10">
                <div class="inline-block orange-text pr-10 font-red">p.small </div>
                <div class="inline-block"> 8.75rem / 14.00px</div>
              </div>
            </div>
            <h4 class="mt-30">font-weight classes and variables</h4>
            <div class="gray-100 p-20 mt-10">
              <div>
                <div class="inline-block font-red pr-10">.regular</div>
                <div class="inline-block">
                   { font-weight: var(--reg)}
                </div>
              </div>
              <div>
                <div class="inline-block mt-10 font-red pr-10">.semibold</div>
                <div class="inline-block">
                  { font-weight: var(--semi)}
                </div>
              </div>
              <div>
                <div class="inline-block mt-10 pr-10 font-red">.bold</div>
                <div class="inline-block">
                  { font-weight: var(--bold)}
                </div>
              </div>
            </div>
          </div>

          </div>
        </section>

        <div class="divider"></div>

        <section id="Readability">
          <div class="content-band" id="readability">
          
          <h2 class="pb-20">Readability</h2>

          <h3>Line Height</h3>
          <p>
            Adequate line-height is important for content accessibility. 
            Design System line-height values are generally based in porportion to the major-third scaling of our font-sizes.
          </p>

          <div class="mt-20 p-30 mb-30 white max-w800">
            <a class="font-red underline" href="https://www.w3.org/TR/WCAG20-TECHS/C21.html" target="_blank">W3.org:  Techniques for WCAG 2.0   </a>
          <p> 
            "Many people with cognitive disabilities have trouble tracking lines of text when a block of text is single spaced. 
            Providing spacing between 1.5 to 2 allows them to start a new line more easily once they have finished the previous one."
          </p>
          </div>


          <h3>Line Width</h3>
          <p>
            Reader’s eyes have a difficult time connecting from one sentence to
            the next when blocks of content extend too far across the screen,
            therefore the maximum line length of a single column of text is
            720px, not including padding or margins.
          </p>

          </div>
        </section>

        <div class="divider"></div>

        <section> 
        <div class="content-band" id="links">
          <h3>Links</h3>
          <p>
            Links are underlined and set to the primary color by default.
          </p>
          <p>
            Linked content should open in the same tab. Opening links in a new
            tab can be disorienting to users, who additionally will not be able
            to use their browser’s back button for navigation, limiting their
            control over navigation. If a link must open in a new tab, provide a
            spoken warning for people using screen readers alerting them that
            the link will open in a new tab.
          </p>
        </div>
        </section>

        <div class="divider"></div>

        <section id="Accessibility">
          <div class="content-band" id="accessibility">
          <h3>Accessibility</h3>
          <p>
            Avoid using images of text as doing so is problematic for mobile
            users, users with low vision, and people using screen readers. For
            more information, see
            <a class="font-red" href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-text-presentation.html">
              WCAG 2.0 Success Criteria for Images of Text
            </a>.
          </p>
          <p>
            Images must never contain informational or instructional text as it
            will be inaccessible to people using screen readers.
          </p>
          <h4 class="mt-30">Visual Contrast</h4>
          <ul class="bullet-red">
            <li>
              Text must have a contrast ratio of at least 4.5:1, except for
              large text (18.66px and above) which must have a contrast ratio of
              at least 3:1.
            </li>
            <li>
              There is no contrast requirement for incidental text or text that
              is part of a logo.
            </li>
            <li>
              provides context/descriptions to search engine crawlers, helping
              to improve SEO
            </li>
            <li>
              Text must be able to be resized without assistive technology up to
              200 percent without loss of content or functionality.
            </li>
          </ul>
          <p>
            For more information, see
            <a class="font-red"
              href="WCAG 2.0 Success Criteria for Contrast
            (Enhanced)"
              >WCAG 2.0 Success Criteria for Contrast (Enhanced)</a
            >.
          </p>
          </div>
        </section>
        <!-- <div class="content-menu"></div> -->
      </div>

      <StickyTOC />

       <!-- <div class="container max-w200 p-20">
          <nav class="content-menu">
            <ol class="content-menu-list">
              <li class=""><a href="#font-family">Font Family</a></li>
              <li class=""><a href="#headings">Headings</a></li>
              <li class=""><a href="#paragraphs">Paragraphs</a></li>
              <li class=""><a href="#readability">Readability</a></li>
              <li class=""><a href="#links">Links</a></li>
              <li class=""><a href="#accessibility">Accessibility</a></li>
            </ol>
          </nav>
        </div> -->

      </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import StickyTOC from "../StickyTOC.vue";
import SubFooter from "../../components/SubFooter";

export default {
  name: "Typography",
  components: {
    TopLevelLeftNav,
    StickyTOC,
    SubFooter,
  },
};
</script>

