<!-- <template>
    <div class="sidebar-container">
      <div class="menu">
        <ul class="menu-items" role="menu">
          <li class="menu-title current">
            {{ currentRoute.replaceAll("-", " ") }}
          </li>
          <li tabindex="0" role="menuitem"
            v-for="(item, index) in navigationDictionary[currentRoute]"
            :key="index"
            :class="{
              active: $router.currentRoute.path.split('/')[2] === item ||
              $router.currentRoute.path.split('/')[3] === item,

            }"
            @click="handleSubItemClick(item)"
          >
            {{ item.replaceAll("-", " ") }}
          </li>
        </ul>
      </div>
    </div>
</template> -->

<template>
  <div class="sidebar-container">
    <div class="sidebar-menu">
      <ul class="menu-items" role="menu">
        <li class="menu-title mb-30">
          {{
            currentRoute
              .replaceAll("-", " ")
              .replaceAll("utilities", "Utility Library")
              .replaceAll("philosophy standards", "philosophy & standards")
          }}
        </li>
        <li
          tabindex="0"
          role="menuitem"
          aria-label="link"
          v-for="(item, index) in navigationDictionary[currentRoute]"
          :key="index"
          :class="{
            active: $router.currentRoute.path.split('/')[3] === item,
          }"
          @click="handleSubItemClick(item)"
        >
          {{ item.replaceAll("-", " ") }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopLevelLeftNav",
  data: function() {
    return {
      navigationDictionary: {
        "philosophy-standards": [
          "experience-pillars",
          "experience-principles",
          "accessibility-standards",
          "terms-&-definitions",
        ],
        foundations: [
          "photography",
          "illustration",
          "icons",
          "typography",
          "color",
          "layout-&-spacing",
        ],
        utilities: [
          "flexbox",
          "size-&-spacing",
          "hover-&-focus",
          "fonts",
          "images",
          "colors",
        ],
      },
      currentRoute: "",
      currentSubRoute: "",
      isDevPortal: false,
    };
  },
  created: function() {
    console.log(this.$router.currentRoute.path.split('/')[2])
    if (
      this.$router.currentRoute.path.split("/")[2] === "philosophy-standards"
    ) {
      this.currentRoute = "philosophy-standards";
    }
    if (this.$router.currentRoute.path.split("/")[2] === "foundations") {
      this.currentRoute = "foundations";
    }
    if (this.$router.currentRoute.path.split("/")[2] === "utilities") {
      this.currentRoute = "utilities";
    }
    if (!this.$router.currentRoute.path.includes("design-system")) {
      this.isDevPortal = true;
    }
    this.currentSubRoute = this.$router.currentRoute.path.split("/")[3];
    if (this.isDevPortal) {
      this.currentRoute = "tools";
      this.currentSubRoute = this.$router.currentRoute.path.split("/")[2];
    }
  },
  methods: {
    handleSubItemClick: function(item) {
      if (item != this.currentSubRoute) {
        if (this.isDevPortal) {
          this.$router.push(`/tools/${item}`);
        } else {
          this.$router.push(`/design-system/${this.currentRoute}/${item}`);
        }
      }
    },
  },
};
</script>
